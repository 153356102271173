import React, {useEffect, useState} from "react";
import styles from "./CompanyCreateQuestionnaire.module.scss"
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";

export const CompanyCreateQuestionnaire = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();

    const { register, setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            q_name: '',
            q_description: ''
        }
    })
    const [questions, setQuestions] = useState([]);  // Store editable questions array
    const [type, setType] = useState("yes_no");
    const [scale, setScale] = useState(10);
    const onSubmit = async (data) => {
         questions.map((item) => {
             if(type === 'scale') {
                   item.scale_max = +scale
             }
            return item.type = type
        })
        let send_data = {
            name: data.q_name,
            description: data.q_description,
            status: "active",
            questions: questions
        }

        try {
            let res = await axiosInstance.post(`/questionnaires`, send_data)
            if(res.status === 201 || res.status === 200) {
                navigate(`/admin_spotlight`)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const newQuestion = () => {
        setQuestions((prev) => [...prev, { text: "" }]);
    };
    const deleteQuestion = (item) => {
        setQuestions((prev) => prev.filter((q) => q !== item));
    }
    const handleQuestionChange = (index, value) => {
        setQuestions((prev) =>
            prev.map((q, i) => (i === index ? { ...q, text: value } : q))
        );
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    };
    const handleScaleChange = (event) => {
        setScale(event.target.value)
    }

    return(
        <div className={styles.page__content}>
            <div className={styles.page__head_container}>
                <h1 className={styles.page__title}>New Questionnaire</h1>
                <div className={styles.page__head_btn__container}>
                </div>
            </div>

            <div className={styles.page__data_block}>

                <div className={styles.page__data_checkbox}>
                    {[
                        { value: "yes_no", label: "Yes/No" },
                        { value: "scale", label: "Scale" }
                    ].map((option) => (
                        <div className="form-check" key={option.value}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id={`exampleRadios-${option.value}`}
                                value={option.value}
                                checked={type === option.value}
                                onChange={handleTypeChange}
                            />
                            <label className="form-check-label" htmlFor={`exampleRadios-${option.value}`}>
                                {option.label}
                            </label>
                        </div>
                    ))}
                    {
                        type === 'scale' ?
                        <div className={styles.page__data_scale__container}>
                            <input
                                value={scale}
                                className={styles.page__data_scale}
                                type="number"
                                name="scale_number"
                                placeholder="1-10"
                                onChange={handleScaleChange}
                            />
                        </div> : ''
                    }
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data__item}>

                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <div className={styles.page__question_label}>Questionnaires headline</div>
                                    <textarea
                                        {...register("q_name", { required: true })}
                                    />
                                    {errors.q_name && <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <div className={styles.page__question_label}>Questionnaires Description</div>
                                    <textarea
                                        {...register("q_description", { required: false })}

                                    />
                                </div>
                            </div>
                            <div className={styles.page__data_btn__container}>
                                <div className={styles.page__data_btn}
                                     onClick={() => newQuestion()}
                                >New question
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16 27.4985C15.1369 27.4985 14.4375 26.7991 14.4375 25.936V6.06348C14.4375 5.20035 15.1369 4.50098 16 4.50098C16.8631 4.50098 17.5625 5.20035 17.5625 6.06348V25.936C17.5625 26.7991 16.8631 27.4985 16 27.4985Z" fill="#878790"/>
                                        <path d="M25.937 17.5625H6.06445C5.20133 17.5625 4.50195 16.8631 4.50195 16C4.50195 15.1369 5.20133 14.4375 6.06445 14.4375H25.937C26.8001 14.4375 27.4995 15.1369 27.4995 16C27.4995 16.8631 26.8001 17.5625 25.937 17.5625Z" fill="#878790"/>
                                    </svg>
                                </div>
                            </div>

                            <div className={styles.page__question_label}>Questions</div>
                            <div className={styles.page__question_content}>
                                { questions?.map((q, index) => {
                                    return (
                                        <div key={index} className={styles.page__question__item}>
                                        <textarea
                                            value={q.text}
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                                        />
                                            <span className={styles.page__question__delete} onClick={() => deleteQuestion(q)}></span>
                                        </div>
                                    )
                                })
                                }
                            </div>

                            <div className={styles.page__done_container}>
                                {/*<button className={styles.page__cancel_btn} onClick={() => { navigate(`/admin_${location.state.data.role == 'manager' ? 'managers' : 'employee'}`)}}>Cancel</button>*/}
                                <button className={styles.page__done_btn} type="submit">Create</button>
                            </div>

                        </div>
                    </div>

                </form>

            </div>
        </div>
    )
}
