import React, {useEffect, useState} from "react";
import styles from "./UserSession.module.scss"
import "./main_style.scss"
import axiosInstance from "../../../helpers/api";
export const UserSession = () => {
    document.documentElement.classList.add('collaborator')
    const [userData, setUserData] = useState({});
    const progressData = [
        { value: 75, color: "#DC3B95", label: "Life Assessment" },
        { value: 50, color: "#01C5FF", label: "Emotions" },
        { value: 25, color: "#FF4230", label: "Stress" },
    ];
    useEffect(() => {
        let uData = JSON.parse(localStorage.getItem('user_data'))
        setUserData(uData)
        getQuestions();

    }, [])

    const getQuestions = async () => {
        try {
            let res = await axiosInstance.get(`session/questionnaires`)
            console.log(res.data, 'data')
        } catch (error) {
            // setError(error);
        }
    }
    return(
        <div className={styles.page__container}>
            <h4 className={styles.page__sub}>Session name</h4>
            <div className={styles.page__content} id="spotlight_tab">

                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                           role="tab" aria-controls="pills-home" aria-selected="true">Result </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                           role="tab" aria-controls="pills-profile" aria-selected="false">User</a>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">

                        <div className="spotlight_company-box_container">
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon autonomie"></div>
                                <p className="spotlight_company-box_name">Autonomie</p>

                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={7} style={{ width: '70%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '65%'}}>7</span>
                                    <span className="progress_value-item">10</span>
                                </div>

                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon sens"></div>
                                <p className="spotlight_company-box_name">Sens</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={4} style={{ width: '40%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '35%'}}>4</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon progression"></div>
                                <p className="spotlight_company-box_name">Progression</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={9} style={{ width: '90%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '85%'}}>9</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon reconnaissance"></div>
                                <p className="spotlight_company-box_name">Reconnaissance</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={3} style={{ width: '30%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '25%'}}>3</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon connexion"></div>
                                <p className="spotlight_company-box_name">Connexion</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={5} style={{ width: '50%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '45%'}}>5</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                         aria-labelledby="pills-profile-tab">

                        <div className="user_container">
                            <div className="user_content_item">
                                {userData?.profile_image &&
                                <img
                                    src={userData.profile_image}
                                    alt="User"
                                />
                                }
                                <div className="user_content">
                                    <h4 className="user_content_name">{userData.full_name}</h4>
                                    <div><span className="user_content__text">Email</span>{userData.email}</div>
                                    <div><span className="user_content__text">Phone</span>_</div>
                                    <div><span className="user_content__text">Company name</span>_</div>
                                    <div className="user_content__social">
                                        <p className="user_content__social_item">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 30.0625C23.7665 30.0625 30.0625 23.7665 30.0625 16C30.0625 8.2335 23.7665 1.9375 16 1.9375C8.2335 1.9375 1.9375 8.2335 1.9375 16C1.9375 23.7665 8.2335 30.0625 16 30.0625Z" fill="#0077B5"/>
                                                <path d="M8.27539 13.3633H12.0129V23.8133H8.27539V13.3633Z" fill="white"/>
                                                <path d="M19.9434 13.3496C17.2746 13.3496 16.8309 14.8121 16.8309 14.8121V13.3621H13.9434V23.8121H16.8621C16.8621 23.8121 16.8621 18.6371 16.8621 17.7871C16.8621 16.9309 17.3559 15.8059 18.9309 15.8059C20.4996 15.8059 20.6871 17.3434 20.6746 17.5309C20.6559 17.7184 20.6434 23.8121 20.6434 23.8121H23.7246C23.7246 23.8121 23.7246 17.8684 23.7246 16.5621C23.7246 14.2059 22.1496 13.3496 19.9434 13.3496Z" fill="white"/>
                                                <path d="M10.15 11.925C11.1821 11.925 12.0188 11.0883 12.0188 10.0562C12.0188 9.02417 11.1821 8.1875 10.15 8.1875C9.11792 8.1875 8.28125 9.02417 8.28125 10.0562C8.28125 11.0883 9.11792 11.925 10.15 11.925Z" fill="white"/>
                                            </svg>
                                        </p>
                                        <p className="user_content__social_item">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 30.0625C23.7665 30.0625 30.0625 23.7665 30.0625 16C30.0625 8.2335 23.7665 1.9375 16 1.9375C8.2335 1.9375 1.9375 8.2335 1.9375 16C1.9375 23.7665 8.2335 30.0625 16 30.0625Z" fill="url(#paint0_linear_237_454)"/>
                                                <path d="M18.9871 8.1875H13.0184C10.3559 8.1875 8.19336 10.35 8.19336 13.0125V18.9812C8.19336 21.6438 10.3559 23.8062 13.0184 23.8062H18.9871C21.6496 23.8062 23.8121 21.6438 23.8121 18.9812V13.0125C23.8121 10.35 21.6496 8.1875 18.9871 8.1875ZM22.0684 18.9875C22.0684 20.6875 20.6871 22.075 18.9809 22.075H13.0121C11.3121 22.075 9.92461 20.6937 9.92461 18.9875V13.0187C9.92461 11.3187 11.3059 9.93125 13.0121 9.93125H18.9809C20.6809 9.93125 22.0684 11.3125 22.0684 13.0187V18.9875Z" fill="white"/>
                                                <path d="M15.9996 12.0059C13.7996 12.0059 12.0059 13.7996 12.0059 15.9996C12.0059 18.1996 13.7996 19.9934 15.9996 19.9934C18.1996 19.9934 19.9934 18.1996 19.9934 15.9996C19.9934 13.7996 18.1996 12.0059 15.9996 12.0059ZM15.9996 18.4246C14.6621 18.4246 13.5746 17.3371 13.5746 15.9996C13.5746 14.6621 14.6621 13.5746 15.9996 13.5746C17.3371 13.5746 18.4246 14.6621 18.4246 15.9996C18.4246 17.3371 17.3371 18.4246 15.9996 18.4246Z" fill="white"/>
                                                <path d="M20.2982 12.4432C20.6662 12.3836 20.9161 12.0369 20.8565 11.669C20.7968 11.301 20.4502 11.0511 20.0822 11.1107C19.7143 11.1704 19.4644 11.517 19.524 11.885C19.5837 12.2529 19.9303 12.5028 20.2982 12.4432Z" fill="white"/>
                                                <defs>
                                                    <linearGradient id="paint0_linear_237_454" x1="5.29244" y1="26.7076" x2="25.2768" y2="6.72319" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#FEE411"/>
                                                        <stop offset="0.0518459" stop-color="#FEDB16"/>
                                                        <stop offset="0.1381" stop-color="#FEC125"/>
                                                        <stop offset="0.2481" stop-color="#FE983D"/>
                                                        <stop offset="0.3762" stop-color="#FE5F5E"/>
                                                        <stop offset="0.5" stop-color="#FE2181"/>
                                                        <stop offset="1" stop-color="#9000DC"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </p>
                                        <p className="user_content__social_item">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 30.0625C23.7665 30.0625 30.0625 23.7665 30.0625 16C30.0625 8.2335 23.7665 1.9375 16 1.9375C8.2335 1.9375 1.9375 8.2335 1.9375 16C1.9375 23.7665 8.2335 30.0625 16 30.0625Z" fill="#3B5999"/>
                                                <path d="M20.0379 10.7563V8.2125C20.0379 8.2125 17.4379 8.1875 17.2629 8.1875C16.0879 8.1875 14.4004 9.525 14.4004 11.0437V13.7688H11.9629V16.6562H14.3629V23.8125H17.2066V16.6187H19.7191L20.0379 13.8H17.2379C17.2379 13.8 17.2379 12.0687 17.2379 11.7312C17.2379 11.2312 17.6129 10.75 18.1754 10.75C18.5504 10.7375 20.0379 10.7563 20.0379 10.7563Z" fill="white"/>
                                            </svg>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="user_content_item flex-column">
                                <h4 className="user_content_name">KPI</h4>
                                <div className="user_content__progress">
                                    {progressData.map((item, index) => (
                                        <div>
                                            <label>{item.label}</label>
                                            <div className="progress my-2">
                                                <div
                                                    className={`progress-bar`}
                                                    role="progressbar"
                                                    style={{ width: `${item.value}%`, background: `${item.color}` }}
                                                    aria-valuenow={item.value}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100">
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
