import React, {createRef, useEffect, useState} from "react";
import styles from './SuperCreateCompany.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";
import {MigrateModal} from "../../../UI/Modal/Modal";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

export const SuperCreateCompany = () => {
    document.documentElement.classList.add('super_admin')
    const [admins, setAdmins] = useState([])
    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [deleteAdminId, setDeleteAdminId] = useState(null);

    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setSelectedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }
    useEffect(() => {
        getAdmins();
    }, [])
    const getAdmins = async () => {
        try {
            let res = await axiosInstance.get(`admin/users`)
            setAdmins(res.data)
        } catch (error) {
            // setError(error);
        }
    }

    const newAdmin = () => {
        navigate('/super_create_admin')
    };
    const newManager = () => {
        console.log('manager')
    };
    const deleteAdmin = (id) => {
        setMigrateModalIsOpen(true);
        setDeleteAdminId(id)
    }
    const delAdmin = async () => {
        try {
            let res = await axiosInstance.delete(`admin/users/${deleteAdminId}`)
            if(res.status === 200 || res.status === 201 ) {
                closeMigrateModal()
              let filteredItems =   admins.filter((item) =>
                  item.id !== deleteAdminId
                )
                setAdmins(filteredItems)
            }
        } catch (error) {
            // setError(error);
        }
    }
    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }

    // const onChangeTeam = (id, e) => {
    //     console.log(id, 'id')
    // };
    const { register, setError, handleSubmit, formState: { errors }, } = useForm()
    const onSubmit = async (data) => {
        data.admins = admins.map(item => item.id)
        data.logo = selectedImage
        // let token = localStorage.getItem("token")
        try {
            let res = await axiosInstance.post(`admin/companies`, data);
            if (res.status === 201 || res.status === 200) {
                navigate('/super_companies');
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(file); // Convert image to base64 format
        }
    }
    const cancelCreate = () => {
        navigate('/super_companies')
    }
    return(
        <>
            <div className={styles.page__content}>
                <h1 className={styles.page__title}>Company</h1>
                <p className={styles.page__subtitle}>Personal information</p>

                <div className={styles.page__data_block}>
                    <div className={styles.page__data_img_cropper}>
                        <button className={styles.page__data_img_cropper__btn} disabled={selectedImage == ''} onClick={() => cropOpen(true)}>Crop image</button>

                        {cropModal &&
                        <div className={styles.page__data_img_cropper__content}>
                            <Cropper
                                ref={cropperRef}
                                style={{ height: "100%", width: "100%" }}
                                zoomTo={0.2}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                src={selectedImage}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                            />
                            <div className={styles.page__data_img_cropper_block}>
                                <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                                <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                            </div>

                        </div>
                        }

                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={styles.page__data__item}>
                            <div className={styles.page__data_left}>
                                <p className={styles.page__data_sub}>Company logo</p>
                                <div className={styles.page__data_img__container}>
                                    <div className={styles.page__data_img__icon}>
                                        <input type="file" accept="image/*" onChange={onFileChange} />
                                    </div>
                                    {selectedImage && (
                                        <div className={styles.page__data_img_selected}>
                                            <img
                                                src={selectedImage}
                                                alt="Selected"

                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.page__data_input__container}>
                                <div className={styles.page__data_input__block}>
                                    <div className={styles.form_group}>
                                        <label>Company name</label>
                                        <Input input={{
                                            placeholder: "Company name",
                                            name: "name",
                                            type: "text",
                                            ...register("name", { required: true })
                                        }}
                                               className="form-control"
                                        />
                                        {errors.name && <span className='textDanger'>This field is required</span>}
                                    </div>
                                    <div className={styles.form_group}>
                                        <label>Email address</label>
                                        <Input input={{
                                            placeholder: "companyname@gmail.com",
                                            name: "email",
                                            type: "email",
                                            ...register("email", { required: true })
                                        }}
                                               className="form-control"
                                        />
                                        {errors.email?.message ?
                                            <span className='textDanger'>{errors.email.message}</span> :
                                            errors.email &&
                                            <span className='textDanger'>This field is required</span>}
                                    </div>
                                </div>
                                <div className={styles.page__data_input__block}>
                                    <div className={styles.form_group}>
                                        <label>Location</label>
                                        <Input input={{
                                            placeholder: "Street",
                                            name: "location",
                                            type: "text",
                                            ...register("location", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                    <div className={styles.form_group}>
                                        <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                        <Input input={{
                                            placeholder: "+87 5469 554 78",
                                            name: "phone",
                                            type: "number",
                                            ...register("phone", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.page__data__item}>
                            <div className={styles.page__data_left}>
                                <p className={styles.page__subtitle}>Admin</p>
                            </div>
                            <div className={styles.page__data_input__container}>
                                <div className={styles.page__admins_container}>
                                    <div className={styles.page__admins_cards}>
                                        {admins.map((t, i) => {
                                            return(
                                                <div key={i} className={styles.page__admins_card}>
                                                    <div className="">
                                                        <div className={`${styles.page__admins_card__dots}`}
                                                             id="dropdownMenuButton" data-toggle="dropdown"
                                                             aria-haspopup="true" aria-expanded="false">
                                                        </div>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <span className="dropdown-item" onClick={() => deleteAdmin(t.id)}>Delete</span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.page__admins_card__img}>
                                                        {t.image &&
                                                        <img
                                                            src={t.image}
                                                            alt={t.first_name}
                                                        />
                                                        }
                                                    </div>
                                                    <span className={styles.page__admins_card__name}>{t.first_name} &nbsp; {t.last_name}</span>

                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={styles.page__admins_btn__container}>
                                        <div className={styles.page__admins_btn}
                                             onClick={() => newAdmin()}
                                        >New admin
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M16 27.4985C15.1369 27.4985 14.4375 26.7991 14.4375 25.936V6.06348C14.4375 5.20035 15.1369 4.50098 16 4.50098C16.8631 4.50098 17.5625 5.20035 17.5625 6.06348V25.936C17.5625 26.7991 16.8631 27.4985 16 27.4985Z" fill="#878790"/>
                                                <path d="M25.937 17.5625H6.06445C5.20133 17.5625 4.50195 16.8631 4.50195 16C4.50195 15.1369 5.20133 14.4375 6.06445 14.4375H25.937C26.8001 14.4375 27.4995 15.1369 27.4995 16C27.4995 16.8631 26.8001 17.5625 25.937 17.5625Z" fill="#878790"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.page__data__item}>
                            <div className={styles.page__data_left}>
                                <p className={styles.page__subtitle}>Managers</p>
                            </div>
                            <div className={styles.page__data_input__container}>
                                <div className={styles.page__admins_container}>
                                    <div className={styles.page__admins_cards}>
                                        {/*<div className={styles.page__teams_container}>*/}
                                        {/*    {teamsBox.map((t, i) => {*/}
                                        {/*        return(*/}
                                        {/*            <div key={i}>{t.name}*/}
                                        {/*                <div className="form-check-lg form-switch">*/}
                                        {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                                        {/*                           id="flexSwitchCheckDefault" checked={t.inputValue} onChange={(e)=>onChangeTeam(t.id, e)} />*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        )*/}
                                        {/*    })}*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className={styles.page__admins_btn__container}>
                                        <div className={styles.page__admins_btn}
                                             onClick={() => newManager()}
                                        >New manager
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M16 27.4985C15.1369 27.4985 14.4375 26.7991 14.4375 25.936V6.06348C14.4375 5.20035 15.1369 4.50098 16 4.50098C16.8631 4.50098 17.5625 5.20035 17.5625 6.06348V25.936C17.5625 26.7991 16.8631 27.4985 16 27.4985Z" fill="#878790"/>
                                                <path d="M25.937 17.5625H6.06445C5.20133 17.5625 4.50195 16.8631 4.50195 16C4.50195 15.1369 5.20133 14.4375 6.06445 14.4375H25.937C26.8001 14.4375 27.4995 15.1369 27.4995 16C27.4995 16.8631 26.8001 17.5625 25.937 17.5625Z" fill="#878790"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.page__data__item}>
                            <div className={styles.page__data_left}>
                                <p className={styles.page__subtitle}>KPI</p>
                            </div>
                            <div className={styles.page__data_input__container}>
                                <div className={styles.page__data_input__block}>
                                    <div className={styles.form_group}>
                                        <label>Turnover</label>
                                        <Input input={{
                                            placeholder: "0",
                                            name: "turnover",
                                            type: "number",
                                            ...register("turnover", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                    <div className={styles.form_group}>
                                        <label>Absenteeism</label>
                                        <Input input={{
                                            placeholder: "0",
                                            name: "absenteeism",
                                            type: "number",
                                            ...register("absenteeism", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className={styles.page__data_input__block}>
                                    <div className={styles.form_group}>
                                        <label>Sales</label>
                                        <Input input={{
                                            placeholder: "0",
                                            name: "sales",
                                            type: "number",
                                            ...register("sales", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                    <div className={styles.form_group}>
                                        <label htmlFor="exampleFormControlInput1">Margins</label>
                                        <Input input={{
                                            placeholder: "0",
                                            name: "margins",
                                            type: "number",
                                            ...register("margins", { required: false })
                                        }}
                                               className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className={styles.page__done_container}>
                                    <button className={styles.page__cancel_btn} onClick={() => cancelCreate()}>Cancel</button>
                                    <button className={styles.page__done_btn} type="submit">Create</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                <MigrateModal
                    closeMigrateModal = {closeMigrateModal}
                    migrateModalIsOpen ={migrateModalIsOpen}
                    onSuccess={delAdmin}
                    data ={
                      {
                          title: 'Delete company',
                          text: 'Are you sure you want to delete a company?',
                          button_info1: 'No',
                          button_info2: 'Yes',}
                    }
                />
        </div>
        </>
    )
}
