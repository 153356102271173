import React, {createRef, useEffect, useState} from "react";
import styles from './CompanyCreateEmployee.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
export const CompanyCreateEmployee = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState('');
    const [allDepartments, setAllDepartments] = useState([])
    const [department, setDepartment] = useState([])
    const [allTeams, setAllTeams] = useState([])
    const [team, setTeam] = useState([])
    const [allPositions, setAllPositions] = useState([])
    const [position, setPosition] = useState([])
    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setSelectedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }

    useEffect(() => {
        getDepartments();
        getPositions()
    }, [])

    const { register, setError, handleSubmit, formState: { errors }, } = useForm()
    const getPositions = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/work-types`)
            setAllPositions(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getDepartments = async () => {
        try {
            let res = await axiosInstance.get(`departments`)
            setAllDepartments(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('email', data.email);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('phone', data.phone);
        formData.append('department_id', department);
        formData.append('team_id', team);
        formData.append('work_type_id', position);
        formData.append('username', data.username);
        formData.append('password', data.password);
        formData.append('role', 'collaborator');
        selectedImage && formData.append('image', selectedImage);
        try {
            let res = await axiosInstance.post(`company-admin/users`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_employee')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    const handleDepartment = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        let selectedDepartment = null
         allDepartments.forEach((dep) => {
             if(dep.id === selectedValues[0]) {
                 selectedDepartment = dep
             }
        })
        setDepartment(selectedValues[0]);
        setAllTeams(selectedDepartment.teams)
    }
    const handlePosition = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setPosition(selectedValues[0]);
    }
    const handleTeam = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setTeam(selectedValues[0]);
    }
    return (
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Employee</h1>


            <div className={styles.page__data_block}>
                <div className={styles.page__data_img_cropper}>
                    <button className={styles.page__data_img_cropper__btn} disabled={selectedImage == ''} onClick={() => cropOpen(true)}>Crop image</button>

                    {cropModal &&
                    <div className={styles.page__data_img_cropper__content}>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={selectedImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                        />
                        <div className={styles.page__data_img_cropper_block}>
                            <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                            <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                        </div>

                    </div>
                    }

                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.page__data__item}>
                        <div className={styles.page__data_img__container}>
                            <div className={styles.page__data_img__icon}>
                                <input type="file" accept="image/*" onChange={onFileChange} />
                            </div>
                            {selectedImage && (
                                <div className={styles.page__data_img_selected}>
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>First name</label>
                                    <Input input={{
                                        placeholder: "First name",
                                        name: "first_name",
                                        type: "text",
                                        ...register("first_name", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.first_name && <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label>Last name</label>
                                    <Input input={{
                                        placeholder: "Last name",
                                        name: "last_name",
                                        type: "text",
                                        ...register("last_name", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.last_name && <span className='textDanger'>This field is required</span>}
                                </div>
                            </div>
                            <div className={styles.page__data_input__block}>

                                <div className={styles.form_group}>
                                    <label>Email address</label>
                                    <Input input={{
                                        placeholder: "companyname@gmail.com",
                                        name: "email",
                                        type: "email",
                                        ...register("email", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.email?.message ?
                                        <span className='textDanger'>{errors.email.message}</span> :
                                        errors.email &&
                                        <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                    <Input input={{
                                        placeholder: "+87 5469 554 78",
                                        name: "phone",
                                        type: "number",
                                        ...register("phone", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>

                            </div>
                            <div className={styles.page__data_input__block}>

                                <div className={styles.form_group}>
                                    <label>Departments</label>
                                    <select
                                        value={department}
                                        onChange={handleDepartment}
                                        className={`form-select ${styles.page__data_select}`}
                                        aria-label="multiple select example">
                                        {allDepartments.map((department) => {
                                            return(
                                                <option key={department.id} value={department.id}>{department.name}</option>
                                            )
                                        })}
                                    </select>
                                    {/*{errors.email?.message ?*/}
                                    {/*    <span className='textDanger'>{errors.email.message}</span> :*/}
                                    {/*    errors.email &&*/}
                                    {/*    <span className='textDanger'>This field is required</span>}*/}
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="exampleFormControlInput1">Teams</label>
                                    <select
                                        value={team}
                                        onChange={handleTeam}
                                        className={`form-select ${styles.page__data_select}`}
                                        aria-label="multiple select example">
                                        {allTeams.map((team) => {
                                            return(
                                                <option key={team.id} value={team.id}>{team.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={styles.page__data_input__block}>

                                <div className={styles.form_group}>
                                    <label>Positions</label>
                                    <select
                                        value={position}
                                        onChange={handlePosition}
                                        className={`form-select ${styles.page__data_select}`}
                                        aria-label="multiple select example">
                                        {allPositions.map((position) => {
                                            return(
                                                <option key={position.id} value={position.id}>{position.title}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.page__data__item}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__subtitle}>Secure information</p>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Login</label>
                                    <Input input={{
                                        placeholder: "Login",
                                        name: "username",
                                        type: "text",
                                        ...register("username", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.username?.message ?
                                        <span className='textDanger'>{errors.username.message}</span> :
                                        errors.username &&
                                        <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label>Password</label>
                                    <Input input={{
                                        placeholder: "Password",
                                        name: "password",
                                        type: "password",
                                        ...register("password", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.password  && <span className='textDanger'>This field is required</span>}
                                </div>
                            </div>
                            <div className={styles.page__done_container}>
                                <button className={styles.page__cancel_btn} onClick={() => { navigate('/admin_employee')}}>Cancel</button>
                                <button className={styles.page__done_btn} type="submit">Done</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}
