import React, {useEffect, useState} from "react";
import styles from './CompanyTeams.module.scss'
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../helpers/api";
import Constants from "../../../config";

export const CompanyTeams =() => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const baseURL = Constants.baseUrl
    const [teams, setTeams] = useState([])
    useEffect(() => {
        getTeams();
    }, [])
    const getTeams = async () => {
        try {
            let res = await axiosInstance.get(`teams`)
            setTeams(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const editTeam = (data) => {
        navigate('/admin_edit_team', {state: {data}})
    }
    const handleChangeFile = async (e) => {
        const files = e.currentTarget.files
        if (files) {
            let formData = new FormData();
            formData.append('file', files[0]);
            try {
                let res = await axiosInstance.post(`admin/companies/import`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if(res.status === 201 || res.status === 200) {
                    console.log('uploaded ok')
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <div className={`${styles.teams_container} m-0`}>
            <div className={styles.teams_head}>
                <h3 className={styles.teams__title}>All Teams({teams.length})</h3>
                <div className="d-flex">
                    <a href={`${baseURL}companies/sample-csv`}>
                        <div className={styles.teams_head_btn}>Download CSV</div>
                    </a>
                    <div className={styles.teams_head_btn}>
                        <input type="file" onChange={handleChangeFile}/>
                        Import a department</div>
                    <div className={styles.teams_head_btn} onClick={() => { navigate('/admin_create_team')}}>Create a team</div>
                </div>

            </div>
                <div className={styles.teams__main_container}>
                    { teams.map((t, i) => {
                        return(
                            <div key={i} className={styles.teams__users_item} onClick={() => editTeam(t)}>
                                <div className={styles.user__item_img__container}>
                                    {t.image ?
                                        <img
                                            src={t.image}
                                            alt={t.name}
                                        /> : <div className={styles.user__item_empty}></div>
                                    }
                                </div>
                                <h2 className={styles.user__item_position}>{t.name}</h2>
                            </div>
                        )
                    })}
                    {/*<div className={styles.teams__users_item} onClick={() => { navigate('/admin_create_team')}}>*/}
                    {/*    <div className={styles.user_team__icon}>*/}
                    {/*    </div>*/}
                    {/*    <h2 className={styles.user_team}>Create a team</h2>*/}
                    {/*</div>*/}
                </div>

        </div>
    )
}
