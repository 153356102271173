import React, {useEffect, useState} from "react";
import styles from "./UserQuestions.module.scss"
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";

export const UserQuestions = () => {
    const navigate=useNavigate();
    const [questionnaires, setQuestionnaires] = useState([])
    const [activeQuestionnaires, setActiveQuestionnaires] = useState(null)
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [btnDisabled, setBtnDisabled] = useState(true)
    useEffect(() => {
        document.documentElement.classList.add('collaborator')
        getQuestions();
    }, [])
    useEffect(() => {
        if (selectedQuestion?.questions?.every(q => q.answer !== undefined && q.answer !== null)) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
    }, [selectedQuestion]);
    const getQuestions = async () => {
        try {
            let res = await axiosInstance.get(`session/questionnaires`)
            setQuestionnaires(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const setActiveSession = (item) => {
        setActiveQuestionnaires(item)
        setSelectedQuestion(null)
    }
    const selectQuestions = (q) => {
        setSelectedQuestion(q)
    }
    const answer = (item, t) => {
        setSelectedQuestion((prev) => ({
            ...prev,
            questions: prev.questions.map((q) =>
                q.text === item.text ? { ...q, answer: t } : q
            ),
        }));
    };
    const sendAnswers = async () => {
        try {
            let res = await axiosInstance.post(`session/questionnaires/set-answers`, {
                session_id: activeQuestionnaires.uuid,
                answers: selectedQuestion
            })
            if(res.status === 201 || res.status === 200) {
                navigate('/user')
            }
        } catch (error) {
            console.log('error')
        }
    }
    return(
        <div className={styles.page__container}>
            <div className={styles.page_head_btn__container}>
                { questionnaires.map((t, i) => {
                    return(
                        <button className={`${styles.page_head_btn} ${activeQuestionnaires?.uuid == t.uuid ? styles.active : ""}`}
                                key={i} onClick={() => setActiveSession(t)}>Session {i+1}</button>
                    )
                })}
            </div>

            <div className={styles.page__content}>
                <h2 className={styles.page__sub}>Questionnaires</h2>
                {
                    activeQuestionnaires?.questionnaires?.map((j, k) => {
                        return(
                            <ul key={k}>
                                <li className={styles.page__question__name} onClick={() => selectQuestions(j)}>{j.name} {j.status == 1 && <span className={styles.page__question__name_active}></span>} </li>
                            </ul>
                        )
                    })
                }
                <hr/>
                {
                    selectedQuestion &&
                        <div className={styles.page__question_container}>
                            <h4 className={styles.page__question_name}> {selectedQuestion.name}</h4>
                            <p className={styles.page__question_description}> {selectedQuestion.description}</p>
                            {
                                selectedQuestion?.questions?.map((q,i) => {
                                    return(
                                        <div key={i} className={styles.page__question_item}>
                                            <h5 className={styles.page__question_item__text}>{q.text}</h5>
                                            {q.type === "yes_no" ?
                                                <div className={styles.page__question_item__btns}>
                                                    <div className={`${styles.page__question_item_btn} ${q.answer === "yes" ? styles.active : ""}`} onClick={() => answer(q, 'yes')}>Yes</div>
                                                    <div className={`${styles.page__question_item_btn} ${q.answer === "no" ? styles.active : ""}`} onClick={() => answer(q, 'no')}>No</div>
                                                </div>
                                                :
                                                <div className={styles.page__question_item_point__container}>
                                                    {Array.from({ length: q.scale_max }, (_, i) => {
                                                       return(<span className={`${styles.page__question_item_point} ${q.answer == i + 1 ? styles.active : ""}`} key={i} onClick={() => answer(q, i + 1)}>{i+1}</span>)
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className={styles.page__question_btn_container}>
                                {selectedQuestion.status !== 1 &&
                                    <button className={styles.page__question_btn} disabled={btnDisabled} onClick={() => sendAnswers()}>Send</button>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
