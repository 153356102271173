import React, {useEffect, useState} from "react";
import styles from './CompanyPositions.module.scss'
import axiosInstance from "../../../helpers/api";
import Input from "../../../UI/Input/Input";
import {useForm} from "react-hook-form";
import {MigrateModal} from "../../../UI/Modal/Modal";

export const CompanyPositions = () => {
    document.documentElement.classList.add('admin')
    const [positions, setPositions] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [status, setStatus] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const { register, setError, handleSubmit, setValue, formState: { errors } } = useForm()
    useEffect(() => {
        getPositions();
    }, [])

    const getPositions = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/work-types`)
            setPositions(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const openBar = (e) => {
        setIsOpen(e)
        setStatus(false)
        setValue('title', null)
        setValue('description', null)
    }
    const deletePosition = (id) => {
        setDeleteId(id)
        setMigrateModalIsOpen(true);
    }
    const editPosition = (data) => {
        setValue('title', data.title)
        setValue('description', data.description)
        setDeleteId(data.id)
        setIsOpen(true)
        setStatus(true)
    }
    const delPosition = async () => {
        try {
            let res = await axiosInstance.delete(`company-admin/work-types/${deleteId}`)
            if(res.status === 204 ) {
                closeMigrateModal()
                getPositions()
            }
        } catch (error) {
            setError(error);
        }
    }
    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }

    const onSubmit = async (data) => {
        try {
            if(status === false) {
                let res = await axiosInstance.post(`company-admin/work-types`, data);
                if(res.status === 201 || res.status === 200) {
                    getPositions()
                    setIsOpen(false)
                }
            } else {
                let res = await axiosInstance.put(`company-admin/work-types/${deleteId}`, data);
                if(res.status === 201 || res.status === 200) {
                    getPositions()
                    setIsOpen(false)
                }
            }

        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }

    return (
        <div className={`${styles.positions_container} m-0`}>
            <div className={styles.positions_head}>
                <h3 className={styles.positions__title}>All Positions</h3>
                <div className="d-flex">
                    <div className={styles.positions_head_btn} onClick={() => openBar(true)}>Add position</div>
                </div>
            </div>

            <div className={`${styles.positions__create_container} ${isOpen ? styles.positions__create_container__open : ""}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className={styles.positions__form_group}>
                            <label>Position Title</label>
                            <Input input={{
                                placeholder: "Text here",
                                name: "title",
                                type: "text",
                                ...register("title", { required: true })
                            }}
                                   className="form-control"
                            />
                            {errors.title && <span className='textDanger'>This field is required</span>}
                        </div>
                        <div className={styles.positions__form_group}>
                            <label>Position Description</label>
                            <textarea
                                placeholder={"Text here"}
                                name="title"
                                rows={4}
                                {...register('description', { required: true })}
                            />
                            {errors.description && <span className='textDanger'>This field is required</span>}
                        </div>

                    </div>
                    <div className={styles.positions__done_container}>
                        <button className={styles.positions__cancel_btn} onClick={() => openBar(false)}>Cancel</button>
                        <button className={styles.positions__done_btn} type="submit">{status ? 'Edit' : 'Add'}</button>
                    </div>
                </form>
            </div>

            <div className={`spotlight_table-container ${styles.positions__table}`}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Position</th>
                        <th scope="col">Description</th>
                        <th scope="col">Actions</th>
                        <th scope="col"> </th>
                    </tr>
                    </thead>
                    <tbody>
                    { positions.map((t, i) => {
                        return(
                            <tr key={i}>
                                <td className={styles.positions__table_title}>
                                    {t.title}
                                </td>
                                <td>
                                    {t.description}
                                </td>
                                <td className={styles.positions__table_actions}>
                                    <div className={styles.positions__table_edit} onClick={() => {editPosition(t)}}></div>
                                    <div className={styles.positions__table_delete} onClick={() => deletePosition(t.id)}></div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            </div>
            <MigrateModal
                closeMigrateModal = {closeMigrateModal}
                migrateModalIsOpen ={migrateModalIsOpen}
                onSuccess={delPosition}
                data ={
                    {
                        title: 'Delete position',
                        text: 'Are you sure you want to delete a position?',
                        button_info1: 'No',
                        button_info2: 'Yes',}
                }
            />

        </div>
    )
}
