import React, {useEffect, useState, createRef} from "react";
import styles from './CompanyEditDepartment.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useLocation, useNavigate} from "react-router-dom";
import {MigrateModal} from "../../../UI/Modal/Modal";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

export const CompanyEditDepartment = (props) => {
    document.documentElement.classList.add('admin')
    const location = useLocation()
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [changedImg, setChangedImg] = useState( null);
    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const [editId, setEditId] = useState([]);
    const [allActivities, setAllActivities] = useState([])
    const [activity, setActivity] = useState('')
    const [teams, setTeams] = useState([])

    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setChangedImg(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }

    const { register,setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: location.state.data ? {
                name: location.state.data.name,
                email: location.state.data.email,
                phone: location.state.data.phone,
                activity: location.state.data.activity,
            }
            : undefined,
    })
    useEffect(() => {
        getActivities()
        setEditId(location.state.data.id, [location.state.data.id])
        setSelectedImage(location.state.data.image,[ location.state.data.image])
        setActivity(location.state.data.activity);
        setTeams(location.state.data.teams);
    }, [location.state.data.id, location.state.data.logo ])

    const getActivities = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/activities`)
            setAllActivities(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const handleActivity = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setActivity(selectedValues[0]);
    }
    const cancelEdit = () => {
        navigate('/admin_departments')
    }
    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('activity', activity);
        formData.append('email', data.email);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        formData.append('id', editId);
        if(changedImg !== null) {
            formData.append('image', changedImg);
        }
        try {
            let res = await axiosInstance.put(`departments/${editId}`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedImg(reader.result);
                setSelectedImage(reader.result)
            };
            reader.readAsDataURL(image);
        }
    }
    const deleteDepartment = () => {
        setMigrateModalIsOpen(true);
    }
    const delDepartment = async () => {
        try {
            let res = await axiosInstance.delete(`departments/${editId}`)
            if(res.status === 204  ) {
                closeMigrateModal()
                navigate('/admin_departments')
            }
        } catch (error) {
            setError(error);
        }
    }
    const deleteTeam = async (id) => {
        try {
            let res = await axiosInstance.patch(`departments/teams/detach/${id}`, {
                id: id
            })
            console.log(res, 'res')
        } catch (error) {
            console.log('error')
            // setError(error);
        }
    }

    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }
    return(
        <div className={styles.page__content}>
            <div className={styles.page__head_container}>
                <h1 className={styles.page__title}>Edit Department</h1>
                <div className={styles.page__head_btn__container}>
                    <button className={styles.page__head_btn__delete} onClick={() => deleteDepartment()}>Delete Department</button>
                </div>
            </div>
            <div className={styles.page__data_block}>
                <div className={styles.page__data_img_cropper}>
                    <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(true)}>Crop image</button>

                    {cropModal &&
                        <div className={styles.page__data_img_cropper__content}>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={selectedImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                        />
                            <div className={styles.page__data_img_cropper_block}>
                                <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                                <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                            </div>

                    </div>
                    }

                </div>
                <form onSubmit={handleSubmit(onSubmit)}>


                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        <div className={styles.page__data_img_selected}>
                            {selectedImage &&
                            <img
                                src={selectedImage}
                                alt="Selected"
                            />
                            }
                            {changedImg &&
                            <img
                                src={changedImg}
                                alt="Selected"
                                className={styles.page__data_img_changed}
                            />
                            }
                        </div>
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Department name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email  && <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Activity</label>
                                <select
                                    value={activity}
                                    onChange={handleActivity}
                                    className={`form-select ${styles.page__data_select}`}
                                    aria-label="multiple select example">
                                    {allActivities.map((activity) => {
                                        return(
                                            <option key={activity.id} value={activity.id}>{activity.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.phone?.type !== 'custom' ?
                                    errors.phone && <span className='textDanger'>This field is required</span> :
                                    <span className='textDanger'>The phone field format is invalid</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Teams</label>
                                <hr/>
                                <div className={styles.form_group__container}>
                                    { teams.map((t, i) => {
                                        return(
                                            <div key={i} className={styles.form_group_team}>
                                                <div className={styles.form_group_team__name}>
                                                    {t.name}
                                                </div>

                                                <div className={styles.form_group_team_actions}>
                                                    {/*<div className={styles.positions__table_edit} onClick={() => {editActivity(t)}}></div>*/}
                                                    <div className={styles.form_group_team_delete} onClick={() => deleteTeam(t.id)}></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => cancelEdit()}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Edit</button>
                        </div>
                    </div>
                </form>

                <MigrateModal
                    closeMigrateModal = {closeMigrateModal}
                    migrateModalIsOpen ={migrateModalIsOpen}
                    onSuccess={delDepartment}
                    data ={
                        {
                            title: 'Delete department',
                            text: 'Are you sure you want to delete a department?',
                            button_info1: 'No',
                            button_info2: 'Yes',}
                    }
                />
            </div>
        </div>
    )

}
