import React, {useEffect, useState} from "react";
import styles from "./Spotlight.module.scss"
import "./spot.scss"
import axiosInstance from "../../../helpers/api";
import Multiselect from 'multiselect-react-dropdown';
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";
export const CompanySpotlight = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [mails, setMails] = useState([])

    const [modalOpen, setModalOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [allQuestions, setAllQuestions] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState({});
    const [editQuestion, setEditQuestion] = useState({});

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [questions, setQuestions] = useState([]);  // Store editable questions array

    const customStyles = {
        content: {
            padding: '30px',
            maxWidth: '800px',
            width: '100%',
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:'#FFFFFF',
            borderRadius:'12px',
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)'
        },
        overlay: {zIndex: 1000, backgroundColor: 'rgba(10,10,10, 0.5)'}
    };

    useEffect(() => {
        getQuestions()
        getEmails()
    }, [])
    useEffect(() => {
        if (editQuestion) {
            setName(editQuestion.name || "");
            setDescription(editQuestion.description || "");
            setQuestions(editQuestion.questions ? [...editQuestion.questions] : []); // Clone questions array
        }
    }, [editQuestion]);
    const checkItem = (id) => {
        setSelectedQuestions((prev) => ({
            ...prev,
            [id]: !prev[id]  // Toggle selection
        }));
    };
    const getQuestions = async () => {
        try {
            let res = await axiosInstance.get(`questionnaires`)
            setAllQuestions(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getEmails = async () => {
        try {
            let res = await axiosInstance.get(`questionnaires/users/emails`)
            setMails(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList)
    }
    const onRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList)
    }
    const sendData = async () => {
        // console.log(mails, 'mails')
        let emailArr = selectedOptions.map((item) => {
            return item.email
        })
        let qArr =[]
        for (const property in selectedQuestions) {
            if(selectedQuestions[property] === true) {
                qArr.push(property)
            }
        }
        try {
            let res = await axiosInstance.post(`company-admin/questionnaires/bind`,  {
                emails: emailArr,
                questionnaires: qArr
            });
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const editModal = (item) => {
        setEditQuestion(item)
        setModalOpen(true)
    }
    const deleteQuestion = (item) => {
        setQuestions((prev) => prev.filter((q) => q !== item));
    }
    const addQuestion = (type, scale_max) => {
        setQuestions((prev) => [...prev, { text: "", type: type, scale_max: scale_max }]);
    };
    const closeModal= () => {
        setModalOpen(false)
    }
    const handleQuestionChange = (index, value) => {
        setQuestions((prev) =>
            prev.map((q, i) => (i === index ? { ...q, text: value } : q))
        );
    };
    const handleSave = async () => {

        console.log(name, description, 'name desc')
        console.log(questions, 'questions')
        let send_data = {
            id: editQuestion.id,
            status: "active",
            name: name,
            description: description,
            questions: questions
        }
        try {
            let res = await axiosInstance.put(`questionnaires/${editQuestion.id}`, send_data);
            if(res.status === 201 || res.status === 200) {
                getQuestions()
                setModalOpen(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async () => {
        try {
            let res = await axiosInstance.delete(`questionnaires/${editQuestion.id}`);
            if(res.status === 201 || res.status === 200) {
                getQuestions()
                setModalOpen(false)
            }
        } catch (error) {
            console.log(error)
        }

    }
    return(
        <div className={styles.page__container}>
            <h1 className={styles.page__title}>Spotlight</h1>
            <div className={styles.page__content} id="spotlight_tabs">

                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                           role="tab" aria-controls="pills-home" aria-selected="true">Engagement </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                           role="tab" aria-controls="pills-profile" aria-selected="false">Well Bein</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                           role="tab" aria-controls="pills-contact" aria-selected="false">KPI</a>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                         aria-labelledby="pills-home-tab">
                        <div className="spotlight_company">
                            <h4 className="spotlight_company-title">Company Spotlight</h4>
                            <div className="spotlight_company-scores">
                                Actively not engaged: <span className="scores_value">3 score</span>
                            </div>
                        </div>
                        <div className="spotlight_company-box_container">
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon autonomie"></div>
                                <p className="spotlight_company-box_name">Autonomie</p>

                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={7} style={{ width: '70%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '65%'}}>7</span>
                                    <span className="progress_value-item">10</span>
                                </div>

                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon sens"></div>
                                <p className="spotlight_company-box_name">Sens</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={4} style={{ width: '40%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '35%'}}>4</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon progression"></div>
                                <p className="spotlight_company-box_name">Progression</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={9} style={{ width: '90%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '85%'}}>9</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon reconnaissance"></div>
                                <p className="spotlight_company-box_name">Reconnaissance</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={3} style={{ width: '30%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '25%'}}>3</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>
                            <div className="spotlight_company-box">
                                <div className="spotlight_company-box_icon connexion"></div>
                                <p className="spotlight_company-box_name">Connexion</p>
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow={5} style={{ width: '50%' }}
                                         aria-valuemin="0" aria-valuemax="10">
                                    </div>
                                </div>
                                <div className="progress_value-content" style={{width: '100%' }}>
                                    <span className="progress_value-item">1</span>
                                    <span className="progress_value" style={{left: '45%'}}>5</span>
                                    <span className="progress_value-item">10</span>
                                </div>
                            </div>

                        </div>
                        <div className="spotlight_departments-content">
                            <h4 className="spotlight_company-title">Departments</h4>
                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck1">All employees</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck2" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck2">HR
                                        2</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck3" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck3">Managers</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck4" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck4">Sales</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck5" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck5">Developers</label>
                                </div>
                                <div className="spotlight_departments-check">
                                    <input type="checkbox" className="btn-check" id="btncheck6" autoComplete="off"/>
                                    <label className="btn btn-outline-primary" htmlFor="btncheck6">Designers</label>
                                </div>
                            </div>
                        </div>
                        <div className="spotlight_card-container">
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Autonomie</p>
                                    <div className="spotlight_card-progress">7.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">70 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon autonomie"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Sens</p>
                                    <div className="spotlight_card-progress">9.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">90 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon sens"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Progression</p>
                                    <div className="spotlight_card-progress">6.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">30 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon progression"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Reconnaissance</p>
                                    <div className="spotlight_card-progress">7.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">60 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon reconnaissance"></div>
                                </div>
                            </div>
                            <div className="spotlight_card">
                                <div className="spotlight_card-header">
                                    <p className="spotlight_card-name">Connexion</p>
                                    <div className="spotlight_card-progress">5.0%</div>
                                </div>
                                <div className="spotlight_card-content">
                                    <h1 className="spotlight_card-percentage">20 <span className="percentage_sim">%</span> </h1>
                                    <div className="spotlight_card-icon connexion"></div>
                                </div>
                            </div>
                        </div>
                        <div className="spotlight_table-container">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Full name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Problems</th>
                                    <th scope="col">Progress</th>
                                    <th scope="col"> </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>
                                <tr>
                                    <td>Ann Jones</td>
                                    <td>annjones@gmail.com</td>
                                    <td>Head HR</td>
                                    <td>32 %</td>
                                    <td>2</td>
                                    <td>10.0%</td>
                                    <td> </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                         aria-labelledby="pills-profile-tab">Well Bein test tab
                    </div>

                    <div className="tab-pane fade kpi_tab" id="pills-contact" role="tabpanel"
                         aria-labelledby="pills-contact-tab">
                        <div className="kpi_tab__head">
                            <h3 className="kpi_tab-title">Questionnaires</h3>
                            <button className="kpi_tab__head_btn" onClick={() => navigate('/admin_new_questionnaire')}>New Questionnaire</button>
                        </div>

                        <div className="kpi_tab-container">
                            { allQuestions.map((t, i) => {
                                return(
                                    <div key={i} className="question_item">
                                        <h4 className="question_item-subtitle">{t.name}</h4>
                                        <p className="question_item-description">{t.description}</p>
                                        <ol className="question_item-list">
                                            {t.questions ?
                                                t.questions.map((q, j) => {
                                                    return(
                                                        <li key={j} className="question_item-list_item">
                                                            {q.text} ({q.type === 'yes_no' ? 'Yes / No': q.type === 'scale' ? `0-${q.scale_max}` : 'Text' })
                                                        </li>
                                                    )
                                                }) : ''
                                            }
                                        </ol>
                                        <input
                                            className="question_item-checkbox"
                                            type="checkbox"
                                            checked={!!selectedQuestions[t.id]}
                                            onChange={() => checkItem(t.id)}/>
                                        <p className="question_item-edit" onClick={() => {editModal(t)}}></p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="kpi_tab-mail">
                            <p className="kpi_tab-mail_sub">
                                Enter the email address of the employee to whom you want to send the selected questionnaires.
                            </p>
                            <div>
                                <Multiselect
                                    options={mails}
                                    placeholder="Employee Email"// Options to display in the dropdown
                                    displayValue="email" // Property name to display in the dropdown options
                                    value={selectedOptions}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                />
                            </div>
                            <div className="kpi_tab-mails"></div>
                            <button className="btn send_btn" onClick={() => sendData()}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalOpen}
                onRequestClose={false}
                ariaHideApp={false}
                style={customStyles}
                id="q_modal"
            >
                <div className="modal_inside">
                    <div className="modal_header"  onClick={() => closeModal()}>
                        <h4 className="modal_title">Edit Questionnaires</h4>
                        <div className="modal_close"></div>
                    </div>

                    <div className="modal_body">
                        <div className="modal_main_text">Questionnaires headline</div>
                        <textarea
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div className="modal_main_text">Questionnaires Description</div>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className="modal_main_text">Questions</div>
                        <div className="modal_main_question">
                            { questions?.map((q, index) => {
                                return (
                                    <div key={index} className="modal_main_question__item">
                                        <textarea
                                            value={q.text}
                                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                                        />
                                        <span className="modal_main_question__delete" onClick={() => deleteQuestion(q)}></span>
                                    </div>
                                )
                            })
                            }
                        </div>

                        <div className="modal_btn_container">
                            <div className="d-flex">
                                <div className="modal_btn" onClick={() => addQuestion(questions[0].type, questions[0].scale_max)}>➕ Add Question</div>
                                <div className="modal_btn active" onClick={()=> handleSave()}>Edit</div>
                            </div>

                            <div className="modal_btn remove" onClick={()=> handleDelete()}>Delete Questionnaire</div>
                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
