import React, {useEffect, useState} from "react";
import styles from './ManagerTeam.module.scss'
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../helpers/api";
import Constants from "../../../config";
export const ManagerTeam = () => {
    document.documentElement.classList.add('manager')
    const navigate=useNavigate();
    const baseURL = Constants.baseUrl
    const [users, setUsers] = useState([])
    useEffect(() => {
        getTeam();
    }, [])
    const getTeam = async () => {
        try {
            let res = await axiosInstance.get(`account/team`)
            // let res = await axiosInstance.get(`teams`)
            console.log(res, 'gggggg')
            setUsers(res.data.members)
        } catch (error) {
            // setError(error);
        }
    }
    const manageUser = (data) => {
        navigate('/manager_member', {state: {data}})
    }


    return(
        <div className={`${styles.teams_container} m-0`}>
            <div className={styles.teams_head}>
                <h3 className={styles.teams__title}>All Developers</h3>
                <div className="d-flex">
                    {/*<a href={`${baseURL}companies/sample-csv`}>*/}
                    {/*    <div className={styles.teams_head_btn}>Download CSV</div>*/}
                    {/*</a>*/}
                    {/*<div className={styles.teams_head_btn}>*/}
                    {/*    <input type="file" onChange={handleChangeFile}/>*/}
                    {/*    Import a department</div>*/}
                    {/*<div className={styles.teams_head_btn} onClick={() => { navigate('/admin_create_team')}}>Create a team</div>*/}
                </div>

            </div>
            <div className={styles.teams__main_container}>
                { users.map((t, i) => {
                    return(
                        <div key={i} className={styles.teams__users_item} onClick={() => manageUser(t)}>
                            <div className={styles.user__item_img__container}>
                                {t.image ?
                                    <img
                                        src={t.image}
                                        alt={t.name}
                                    /> : <div className={styles.user__item_empty}></div>
                                }
                            </div>
                            <h2 className={styles.user__item_position}>{t.first_name} {t.last_name}</h2>
                        </div>
                    )
                })}
                {/*<div className={styles.teams__users_item} onClick={() => { navigate('/admin_create_team')}}>*/}
                {/*    <div className={styles.user_team__icon}>*/}
                {/*    </div>*/}
                {/*    <h2 className={styles.user_team}>Create a team</h2>*/}
                {/*</div>*/}
            </div>

        </div>
    )



}
