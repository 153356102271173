import React, {useState} from "react";
import styles from './LogIn.module.scss';
import axios from "axios";
import config from "../../config";
import {useNavigate} from 'react-router-dom'
import Input from '../../UI/Input/Input'
import useInput from "../../hooks/useInput";
import useValidation from '../../hooks/useValidation'
import EyeImage from '../../assets/icons/EyeImage.png';
import ClosedEye from '../../assets/icons/ClosedEye.png';
// import TextField from '@mui/material/TextField'
// import {styled} from '@mui/material/styles';

export const LogIn = ({changeRole}) => {
    const navigate = useNavigate()
    const {isNotEmpty, isEmail} = useValidation();
    const [error, setError] = useState(null);
    const {
        value: email,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput(isEmail);

    const {
        value: password,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        togglePassword: passwordShowHandler,
        passwordShown: passwordShow,
        reset: resetPassword,
    } = useInput(isNotEmpty);
    // let role = 'super_admin'
    // document.documentElement.classList.add(role);


    let emailMessage = null;
    if (!email) {
        emailMessage = "Email is required";
    } else if (!emailIsValid) {
        emailMessage = "Invalid email";
    }

    let formIsValid = false;
    if (emailIsValid
        && passwordIsValid
    ) {
        formIsValid = true;
    }
    var classList = document.documentElement.classList;
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
    document.documentElement.classList.add('admin')
    const values = {
        email,
        password,
    }

    let postLogin = async (values) => {
        let formData = {
            identifier: values.email,
            password: values.password,
        }
        try {
            let response = await axios.post(`${config.baseUrl}login`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
            });
            if (response.data?.role === 'superadmin') {
                let token = response.data.token
                let id = response.data.id
                let user_data = {
                    profile_image: response.data.profile_image,
                    full_name: response.data.full_name,
                    email: response.data.email
                }
                localStorage.setItem('userRole', 'superadmin')
                localStorage.setItem('token', token)
                localStorage.setItem('id', id)
                localStorage.setItem('user_data', JSON.stringify(user_data))
                var aClassList = document.documentElement.classList;
                while (aClassList.length > 0) {
                    aClassList.remove(aClassList.item(0));
                }
                document.documentElement.classList.add("super_admin");
                changeRole('superadmin')
                navigate('/super_companies');
            }
            if(response.data?.role ==="company_admin"){
                let token = response.data.token
                let id = response.data.id
                let user_data = {
                    profile_image: response.data.profile_image,
                    full_name: response.data.full_name,
                    email: response.data.email
                }
                localStorage.setItem('userRole', 'company_admin')
                localStorage.setItem('token', token)
                localStorage.setItem('id', id)
                localStorage.setItem('user_data', JSON.stringify(user_data))
                var classList = document.documentElement.classList;
                while (classList.length > 0) {
                    classList.remove(classList.item(0));
                }
                document.documentElement.classList.add("admin");
                changeRole('company_admin')
                navigate('/admin_departments');
            }
            if(response.data?.role ==="manager"){
                let token = response.data.token
                let id = response.data.id
                let user_data = {
                    profile_image: response.data.profile_image,
                    full_name: response.data.full_name,
                    email: response.data.email
                }
                localStorage.setItem('token', token)
                localStorage.setItem('id', id)
                localStorage.setItem('user_data', JSON.stringify(user_data))
                localStorage.setItem('userRole', 'manager')
                var mclassList = document.documentElement.classList;
                while (mclassList.length > 0) {
                    mclassList.remove(mclassList.item(0));
                }
                document.documentElement.classList.add("manager");
                changeRole('manager')
                navigate('/manager');
            }

            if(response.data?.role ==="collaborator"){
                let token = response.data.token
                let id = response.data.id
                let user_data = {
                    profile_image: response.data.profile_image,
                    full_name: response.data.full_name,
                    email: response.data.email
                }
                localStorage.setItem('token', token)
                localStorage.setItem('id', id)
                localStorage.setItem('userRole', 'collaborator')
                localStorage.setItem('user_data', JSON.stringify(user_data))
                var mclassList = document.documentElement.classList;
                while (mclassList.length > 0) {
                    mclassList.remove(mclassList.item(0));
                }
                document.documentElement.classList.add("collaborator");
                changeRole('collaborator')
                navigate('/user');
            }
        } catch (error) {
            console.log(error.response, 'gggggg')
            // Object.entries(error.response.data?.error.message).forEach(([key, value]) => {
            //     setError(key, {
            //         type: 'custom',
            //         message: value?.[0],
            //     });
            // });
            setError(error?.response?.data.error.message);
        }
    }

    const submitHandler = event => {
        setError();
        event.preventDefault();
        if (!formIsValid) {
            return;
        }
        postLogin(values);
        resetEmail();
        resetPassword();
    }
    return (
        <div className={styles.sign_container}>

            <div className={styles.sign_content}>
                <div className={styles.sign_title}>
                    Login
                </div>
                <form className={styles.sign_form} onSubmit={submitHandler}>
                    <div>
                        <Input
                            hasError={emailHasError}
                            errorText={emailMessage}
                            input={{
                                value: email,
                                placeholder: "Email",
                                type: "email",
                                onChange: emailChangeHandler,
                                onBlur: emailBlurHandler
                            }}
                        />
                    </div>
                    <div>
                        <Input
                            hasError={passwordHasError}
                            errorText="Please enter valid password."
                            onClick={passwordShowHandler}
                            image={passwordShow ? EyeImage : ClosedEye}
                            input={{
                                width: "100%",
                                value: password,
                                placeholder: "Password",
                                type: passwordShow ? "text" : "password",
                                onChange: passwordChangeHandler,
                                onBlur: passwordBlurHandler
                            }}
                        />
                    </div>
                    <div className={styles.sign_btn_container}>
                        <button className={styles.btn_fill} type={"submit"}>Login</button>
                    </div>
                </form>
                <div className="textDanger">
                    {error && <span>{error}</span>}
                </div>
            </div>
        </div>
    )
}
