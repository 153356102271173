import React, {useState, useEffect, createRef} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import styles from './SuperEditAdmin.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
export const SuperEditAdmin = (props) => {
    document.documentElement.classList.add('super_admin')
    const navigate = useNavigate();
    const location = useLocation()
    const [editId, setEditId] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [changedImg, setChangedImg] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setChangedImg(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }
    const { register, setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: location.state.data ? {
                first_name: location.state.data.first_name,
                last_name: location.state.data.last_name,
                email: location.state.data.email,
                phone: location.state.data.phone,
                username: location.state.data.username,
                change_password: location.state.data.change_password,
            }
            : undefined,
    })
    useEffect(() => {
        setSelectedImage(location.state.data.image)
        setEditId(location.state.data.id)
    },[location.state.data.image, location.state.data.id])

    const onSubmit = async (eData) => {
        eData.id = editId
        eData.role = 'company_admin'
        if(changedImg !== null) {
            eData.image = changedImg
        }
        try {
            let res = await axiosInstance.put(`admin/users/${editId}`, eData);
            if (res.status === 201 || res.status === 200) {
                let data = location.state.company;
                navigate('/super_edit_company', { state: { data } });
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }

    }
    const handleInputChange = (e) => {
        console.log(e.target.checked, 'event')
        setIsChecked(e.target.checked)
    };
    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedImg(reader.result); // Set the base64 image data
                setSelectedImage(reader.result)
            };
            reader.readAsDataURL(file); // Convert image to base64 format
        }
    }
    const cancelCreate = () => {
        navigate('/super_companies')
    }

    return(
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Admin</h1>
            <div className={styles.page__data_block}>
                <div className={styles.page__data_img_cropper}>
                    <button className={styles.page__data_img_cropper__btn} disabled={selectedImage == ''} onClick={() => cropOpen(true)}>Crop image</button>

                    {cropModal &&
                    <div className={styles.page__data_img_cropper__content}>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={selectedImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                        />
                        <div className={styles.page__data_img_cropper_block}>
                            <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                            <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                        </div>

                    </div>
                    }

                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data__item}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__data_sub}>Admin profile</p>
                            <div className={styles.page__data_img__container}>

                                <div className={styles.page__data_img__icon}>
                                    <input type="file" accept="image/*" onChange={onFileChange} />
                                </div>
                                <div className={styles.page__data_img_selected}>
                                    {selectedImage &&
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                    />
                                    }
                                    {changedImg &&
                                    <img
                                        src={changedImg}
                                        alt="Selected"
                                        className={styles.page__data_img_changed}
                                    />
                                    }
                                </div>

                            </div>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>First name</label>
                                    <Input input={{
                                        placeholder: "First name",
                                        name: "first_name",
                                        type: "text",
                                        ...register("first_name", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.first_name && <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label>Last name</label>
                                    <Input input={{
                                        placeholder: "Last name",
                                        name: "last_name",
                                        type: "text",
                                        ...register("last_name", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.last_name && <span className='textDanger'>This field is required</span>}
                                </div>
                            </div>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Email address</label>
                                    <Input input={{
                                        placeholder: "companyname@gmail.com",
                                        name: "email",
                                        type: "email",
                                        ...register("email", { required: true })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.email?.message ?
                                        <span className='textDanger'>{errors.email.message}</span> :
                                        errors.email &&
                                        <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                    <Input input={{
                                        placeholder: "+87 5469 554 78",
                                        name: "phone",
                                        type: "number",
                                        ...register("phone", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.page__data__item}>
                        <div className={styles.page__data_left}>
                            <p className={styles.page__subtitle}>Secure information</p>
                        </div>
                        <div className={styles.page__data_input__container}>
                            <div className={styles.page__data_input__block}>
                                <div className={`${styles.form_group} ${styles.form_group_check}`}>
                                    <label htmlFor="checkPass">Edit Password</label>
                                    <Input onChangeCapture={handleInputChange} input={{
                                        id: "checkPass",
                                        placeholder: "",
                                        name: "change_password",
                                        type: "checkbox",
                                        ...register("change_password", { required: false })
                                    }}
                                           className="form-control switch"
                                    />
                                </div>

                            </div>
                            <div className={styles.page__data_input__block}>
                                <div className={styles.form_group}>
                                    <label>Login</label>
                                    <Input input={{
                                        placeholder: "Login",
                                        name: "username",
                                        type: "text",
                                        disabled: !isChecked,
                                        ...register("username", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.username?.message ?
                                        <span className='textDanger'>{errors.username.message}</span> :
                                        errors.username &&
                                        <span className='textDanger'>This field is required</span>}
                                </div>
                                <div className={styles.form_group}>
                                    <label>Password</label>
                                    <Input input={{
                                        placeholder: "Password",
                                        name: "password",
                                        type: "password",
                                        disabled: !isChecked,
                                        ...register("password", { required: false })
                                    }}
                                           className="form-control"
                                    />
                                    {errors.password  && <span className='textDanger'>This field is required</span>}
                                </div>
                            </div>
                            <div className={styles.page__done_container}>
                                <button className={styles.page__cancel_btn} onClick={() => cancelCreate()}>Cancel</button>
                                <button className={styles.page__done_btn} type="submit">Edit</button>
                            </div>
                        </div>
                    </div>



                </form>
            </div>


        </div>
    )


}
