import React, {createRef, useEffect, useState} from "react";
import styles from './CompanyEditTeam.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useLocation, useNavigate} from "react-router-dom";
import {MigrateModal} from "../../../UI/Modal/Modal";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
export const CompanyEditTeam = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [migrateModalIsOpen, setMigrateModalIsOpen] = useState(false);
    const location = useLocation()
    const [editId, setEditId] = useState([]);
    const [deleteTeamId, setDeleteTeamId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [changedImg, setChangedImg] = useState( null);
    const [allDepartments, setAllDepartments] = useState([])
    const [department, setDepartment] = useState([])
    const [allEmployee, setAllEmployee] = useState([])
    const [manager, setManager] = useState([])
    const [members, setMembers] = useState([])
    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setChangedImg(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }

    const { register,setError, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: location.state.data ? {
                name: location.state.data.name,
                our_moto: location.state.data.our_moto,
                members_count: location.state.data.members_count
            }
            : undefined,
    })
    useEffect(() => {
        getDepartments();
        getEmployee();
        setDepartment(location.state.data.department_id);
        setManager(location.state.data.manager_id);
        setMembers(location.state.data.members);
        setEditId(location.state.data.id, [location.state.data.id])
        setSelectedImage(location.state.data.image,[ location.state.data.image])
    }, [location.state.data.id, location.state.data.image ])
    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('our_moto', data.our_moto);
        formData.append('id', editId);
        formData.append('department_id', department);
        formData.append('manager_id', manager);
        // selectedImage && formData.append('image', selectedImage);
        if(changedImg !== null) {
            formData.append('image', changedImg);
        }
        try {
            let res = await axiosInstance.put(`teams/${editId}`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_teams')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const getDepartments = async () => {
        try {
            let res = await axiosInstance.get(`departments`)
            setAllDepartments(res.data)
            if(location.state.data.department_id == null) {
                setDepartment(res.data[0].id)
            }
        } catch (error) {
            // setError(error);
        }
    }
    const handleDepartment = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setDepartment(selectedValues[0]);
    }
    const getEmployee = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/users?role=collaborator`)
            setAllEmployee(res.data)
        } catch (error) {
            // setError(error);
        }
    }
    const handleManager = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setManager(selectedValues[0]);
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChangedImg(reader.result); // Set the base64 image data
                setSelectedImage(reader.result)
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    const deleteTeam = () => {
        setMigrateModalIsOpen(true);
        setDeleteTeamId(editId)
    }
    const delTeam = async () => {
        try {
            let res = await axiosInstance.delete(`teams/${deleteTeamId}`)
            if(res.status === 204 ) {
                closeMigrateModal()
                navigate('/admin_teams')
            }
        } catch (error) {
            // setError(error);
        }
    }
    function closeMigrateModal(){
        setMigrateModalIsOpen(false);
    }
    return (
        <div className={styles.page__content}>
            <div className={styles.page__head_container}>
                <h1 className={styles.page__title}>Edit Team</h1>
                <div className={styles.page__head_btn__container}>
                    <button className={styles.page__head_btn__delete} onClick={() => deleteTeam()}>Delete Team</button>
                </div>
            </div>
            <div className={styles.page__data_block}>
                <div className={styles.page__data_img_cropper}>
                    <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(true)}>Crop image</button>

                    {cropModal &&
                    <div className={styles.page__data_img_cropper__content}>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={selectedImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                        />
                        <div className={styles.page__data_img_cropper_block}>
                            <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                            <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                        </div>

                    </div>
                    }

                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        <div className={styles.page__data_img_selected}>
                            {selectedImage &&
                            <img
                                src={selectedImage}
                                alt="Selected"
                            />
                            }
                            {changedImg &&
                            <img
                                src={changedImg}
                                alt="Selected"
                                className={styles.page__data_img_changed}
                            />
                            }
                        </div>
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Team name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Our moto</label>
                                <Input input={{
                                    placeholder: "Our moto",
                                    name: "our_moto",
                                    type: "text",
                                    ...register("our_moto", { required: false })
                                }}
                                       className="form-control"
                                />

                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Departments</label>
                                <select
                                    value={department}
                                    onChange={handleDepartment}
                                    className={`form-select ${styles.page__data_select}`}
                                    aria-label="multiple select example">
                                    {allDepartments.map((department) => {
                                        return(
                                            <option key={department.id} value={department.id}>{department.name}</option>
                                        )
                                    })}
                                </select>
                                {/*{errors.name && <span className='textDanger'>This field is required</span>}*/}
                            </div>
                            <div className={styles.form_group}>
                                <label>Managers</label>
                                <select
                                    value={manager}
                                    onChange={handleManager}
                                    className={`form-select ${styles.page__data_select}`}
                                    aria-label="multiple select example">
                                    {allEmployee.map((employee) => {
                                        return(
                                            <option key={employee.id} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                                        )
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>

                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Number of people ({location.state.data?.members_count || 0})</label>
                                <hr/>
                                { members.map((member, ) => {
                                    return(
                                        <p key={member.id} className={styles.teams__users_item}>{member.first_name + ' ' + member.last_name}</p>
                                    )
                                })}

                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => { navigate('/admin_teams')}}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Edit</button>
                        </div>
                    </div>
                </form>
                <MigrateModal
                    closeMigrateModal = {closeMigrateModal}
                    migrateModalIsOpen ={migrateModalIsOpen}
                    onSuccess={delTeam}
                    data ={
                        {
                            title: 'Delete team',
                            text: 'Are you sure you want to delete team?',
                            button_info1: 'No',
                            button_info2: 'Yes',}
                    }
                />
            </div>
        </div>
    )
}
