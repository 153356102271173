import React, {createRef, useEffect, useState} from "react";
import styles from './Accoint.module.scss'
import Input from '../../../UI/Input/Input'
import {useForm} from "react-hook-form";
import axiosInstance from "../../../helpers/api";
import {useNavigate} from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
export const CompanyCreateDepartment = () => {
    document.documentElement.classList.add('admin')
    const navigate=useNavigate();
    const [selectedImage, setSelectedImage] = useState('');
    const [allActivities, setAllActivities] = useState([])
    const [activity, setActivity] = useState('')

    const [cropModal, setCropModal] = useState(false)
    const [cropData, setCropData] = useState("");
    const cropperRef = createRef();
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setSelectedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            setCropModal(false)
        }
    };
    const cropOpen = (status) => {
        setCropModal(status)
    }

    useEffect(() => {
        getActivities()
    }, [])

    const getActivities = async () => {
        try {
            let res = await axiosInstance.get(`company-admin/activities`)
            setAllActivities(res.data)
            setActivity(res.data[0].id)
        } catch (error) {
            console.log(error)
        }
    }
    const handleActivity = (event) => {
        const options = Array.from(event.target.selectedOptions);
        const selectedValues = options.map(option => +option.value);
        setActivity(selectedValues[0]);
    }

    const state = {
        options: [{name: 'Option 1', id: 1},{name: 'Option 2', id: 2}]
    };

    const onSelect1 = (selectedList, selectedItem) => {
        console.log(selectedList, selectedItem)
    }

    const onRemove1 = (selectedList, removedItem) => {
        console.log(selectedList, removedItem)
    }
    const { register, setError, handleSubmit, formState: { errors }, } = useForm()
    const onSubmit = async (data) => {

        let formData = new FormData();
        formData.append('activity', activity);
        formData.append('email', data.email);
        formData.append('name', data.name);
        formData.append('phone', data.phone);
        selectedImage && formData.append('image', selectedImage);
        try {
            let res = await axiosInstance.post(`departments`, formData)
            if(res.status === 201 || res.status === 200) {
                navigate('/admin_departments')
            }
        } catch (error) {
            Object.entries(error.response.data?.errors).forEach(([key, value]) => {
                setError(key, {
                    type: 'custom',
                    message: value?.[0],
                });
            });
        }
    }
    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result); // Set the base64 image data
            };
            reader.readAsDataURL(image); // Convert image to base64 format
        }
    }
    return(
        <div className={styles.page__content}>
            <h1 className={styles.page__title}>Department</h1>
            <div className={styles.page__data_block}>
                <div className={styles.page__data_img_cropper}>
                    <button className={styles.page__data_img_cropper__btn} disabled={selectedImage == ''} onClick={() => cropOpen(true)}>Crop image</button>

                    {cropModal &&
                    <div className={styles.page__data_img_cropper__content}>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: "100%", width: "100%" }}
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={selectedImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            guides={true}
                        />
                        <div className={styles.page__data_img_cropper_block}>
                            <button className={styles.page__data_img_cropper__btn} onClick={() => cropOpen(false)}>Cancel</button>
                            <button className={styles.page__data_img_cropper__btn} onClick={getCropData}>Done</button>
                        </div>

                    </div>
                    }

                </div>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={styles.page__data_img__container}>
                        <div className={styles.page__data_img__icon}>
                            <input type="file" accept="image/*" onChange={onFileChange} />
                        </div>
                        {selectedImage && (
                            <div className={styles.page__data_img_selected}>
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                />
                            </div>
                        )}
                    </div>

                    <div className={styles.page__data_input__container}>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Department name</label>
                                <Input input={{
                                    placeholder: "Candidate’s name",
                                    name: "name",
                                    type: "text",
                                    ...register("name", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.name && <span className='textDanger'>This field is required</span>}
                            </div>
                            <div className={styles.form_group}>
                                <label>Email address</label>
                                <Input input={{
                                    placeholder: "companyname@gmail.com",
                                    name: "email",
                                    type: "email",
                                    ...register("email", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.email?.message ?
                                    <span className='textDanger'>{errors.email.message}</span> :
                                    errors.email &&
                                    <span className='textDanger'>This field is required</span>}
                            </div>
                        </div>
                        <div className={styles.page__data_input__block}>
                            <div className={styles.form_group}>
                                <label>Activity</label>
                                {/*<Multiselect*/}
                                {/*    options={state.options} // Options to display in the dropdown*/}
                                {/*    selectedValues={state.selectedValue} // Preselected value to persist in dropdown*/}
                                {/*    onSelect={onSelect1} // Function will trigger on select event*/}
                                {/*    onRemove={onRemove1} // Function will trigger on remove event*/}
                                {/*    displayValue="name" // Property name to display in the dropdown options*/}
                                {/*/>*/}
                                {/*<select className="form-control" id="activity" {...register("activity")} onChange={(e) => setMyValue(e.target.value)}*/}
                                {/*        defaultValue={'1'}>*/}
                                {/*    <option selected disabled value='1'>activity1</option>*/}
                                {/*    <option value='2'>activity2</option>*/}
                                {/*    <option value='3'>activity3</option>*/}
                                {/*    <option value='4'>activity4</option>*/}
                                {/*    <option value='5'>activity5</option>*/}
                                {/*</select>*/}



                                <select
                                    value={activity}
                                    onChange={handleActivity}
                                    className={`form-select ${styles.page__data_select}`}
                                    aria-label="multiple select example">
                                    {allActivities.map((activity) => {
                                        return(
                                            <option key={activity.id} value={activity.id}>{activity.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="exampleFormControlInput1">Phone Number</label>
                                <Input input={{
                                    placeholder: "+87 5469 554 78",
                                    name: "phone",
                                    type: "number",
                                    ...register("phone", { required: true })
                                }}
                                       className="form-control"
                                />
                                {errors.phone?.type !== 'custom' ?
                                    errors.phone && <span className='textDanger'>This field is required</span> :
                                    <span className='textDanger'>The phone field format is invalid</span>}
                            </div>
                        </div>
                        <div className={styles.page__done_container}>
                            <button className={styles.page__cancel_btn} onClick={() => { navigate('/admin_departments')}}>Cancel</button>
                            <button className={styles.page__done_btn} type="submit">Done</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
